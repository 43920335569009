<template>
  <section>
    <b-row>
      <b-col sm="12">
        <aom-skeleton-loader v-if="isLoading" />
      </b-col>
    </b-row>
    <b-row v-if="!isLoading">
      <b-col sm="12">
        <ul class="list-style-none">
          <li v-if="!surveysList || (surveysList && !surveysList.length)">
            <b-row>
              <b-col
                sm="12"
                class="text-center"
              >
                <b-card> Not found surveys </b-card>
              </b-col>
            </b-row>
          </li>
          <li
            v-for="(survey, index) in surveysList"
            v-else
            :key="index"
            class="survey-wrapper"
          >
            <aom-languages>
              <template slot-scope="props">
                <b-row>
                  <b-col
                    sm="5"
                    class="heading-wrapper"
                  >
                    <b class="mb-1">Title</b>
                  </b-col>
                  <b-col
                    sm="3"
                    class="heading-wrapper"
                  >
                    <b class="mb-1">Match</b>
                  </b-col>
                  <b-col
                    sm="2"
                    class="heading-wrapper"
                  >
                    <b class="mb-1">Status</b>
                  </b-col>
                  <b-col
                    sm="1"
                    class="heading-wrapper"
                  >
                    <b class="mb-1">Reminders</b>
                  </b-col>
                  <b-col
                    sm="1"
                    class="heading-wrapper"
                  >
                    <b class="mb-1">Detail</b>
                  </b-col>
                </b-row>
                <b-row class="mt-2">
                  <b-col sm="5">
                    {{ getTitleTranslation(survey, props.localeId) }}
                  </b-col>
                  <b-col sm="3">
                    {{ getMatchName(survey) }}
                  </b-col>
                  <b-col sm="2">
                    <b-badge :variant="STATUS_COLOR[getStatus(survey)]">
                      {{ getStatus(survey) }}
                    </b-badge>
                  </b-col>
                  <b-col sm="1">
                    {{ getNumberOfReminders(survey) }}
                  </b-col>
                  <b-col sm="1">
                    <b-button
                      v-b-toggle="`collapse-matching-${index}`"
                      variant="outline-none"
                      class="btn-icon"
                    >
                      <feather-icon
                        icon="ChevronDownIcon"
                        size="14"
                      />
                    </b-button>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="12">
                    <b-collapse
                      :id="`collapse-matching-${index}`"
                      class="mt-0"
                      accordion="other-questions"
                      role="tabpanel"
                      @show="fetchQuestionAnswers(survey)"
                    >
                      <b-row>
                        <b-col sm="12">
                          <aom-skeleton-loader
                            v-if="isFetching"
                            :loader-type="loaderTypes.SHORT"
                          />
                        </b-col>
                      </b-row>
                      <template v-if="!isFetching">
                        <b-row
                          v-for="(question, i) in surveyQuestionAns"
                  
                          :key="question.id"
                        >
                          <b-col
                            sm="12"
                            class="question-wrapper"
                          >
                            <p>
                              <b>Question {{ i + 1 }}:
                                {{
                                  getQuestionTextTranslation(
                                    question.translations,
                                    props.localeId
                                  )
                                }}</b>
                            </p>
                            <p class="mb-50">
                              Answer: &nbsp;
                              <span
                                v-if="
                                  question.type_id === questionTypes.TEXT &&
                                    question.answers.length > 0
                                "
                              >{{ question.answers[0].answer_text }}</span>
                              <span
                                v-if="question.type_id === questionTypes.SCALE"
                              >{{
                                getStatementsChoicesTranslation(
                                  question,
                                  props.localeId
                                )
                              }}</span>
                              <span
                                v-if="question.type_id === questionTypes.CHOICE"
                              >{{
                                getQuestionChoicesAnswerTextTranslation(
                                  question,
                                  props.localeId
                                )
                              }}</span>
                              <span v-else>&nbsp;</span>
                            </p>
                          </b-col>
                        </b-row>
                      </template>
                      <section
                        v-if="surveyQuestionAns.length === 0 && !isFetching"
                      >
                        <div class="text-center m-4">
                          <b-avatar
                            size="72"
                            variant="primary"
                            class="mr-2"
                          >
                            <feather-icon
                              icon="FileIcon"
                              size="42"
                            />
                          </b-avatar>
                          <h3 class="mt-1">
                            Survey not answered.
                          </h3>
                          <p class="text-muted">
                            User has yet to start or complete this survey.
                          </p>
                        </div>
                      </section>
                    </b-collapse>
                  </b-col>
                </b-row>
              </template>
            </aom-languages>
          </li>
        </ul>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCollapse,
  VBToggle,
  BButton,
  BBadge,
  BAvatar,
  BCard,
} from "bootstrap-vue";
import { mapGetters } from "vuex";
import { questionTypes, loaderTypes, localesDisplay } from "@models";
import { surveyService } from "@/services";
import AomSkeletonLoader from "@aom-core/AomSkeletonLoader.vue";
import { makeErrorToast } from "@/libs/utils";
import AomLanguages from "@aom-core/AomLanguages";
import { STATUS_COLOR } from "@/libs/utils";

export default {
  name: "ParticipantSurveys",
  components: {
    BRow,
    BCol,
    BCollapse,
    BButton,
    AomSkeletonLoader,
    AomLanguages,
    BBadge,
    BAvatar,
    BCard,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  props: {
    user: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  data() {
    return {
      surveysList: [],
      isLoading: false,
      isFetching: false,
      surveyQuestionAns: [],
    };
  },

  computed: {
    ...mapGetters("programs", ["defaultProgramId", "defaultProgram"]),
    textDirection() {
      return "text-left";
    },
    userApplication() {
      return this.defaultProgram.application_set.applications
        .filter(a => a.roles.find(r => r.id === this.user[0].role_id))
        .shift();
    },
    userFormId() {
      return this.userApplication?.id;
    },
  },
  created() {
    this.fetchUserSurveys();
  },
  methods: {
    async fetchUserSurveys() {
      try {
        this.isLoading = true;
        const programId = this.$route.params.id;
        const userId = this.$route.params.participantId;
        const result = await surveyService.getUsersSurveyList(
          programId,
          userId
        );
        const { data } = result;
        this.surveysList = data.items;
      } catch (e) {
        console.log(e);
        this.$toast(makeErrorToast("Error. Fetching users survey list"));
        this.$log.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    getTitleTranslation(survey, localeId) {
      const t = survey.form.translations.filter(
        t => t.locale_id === localeId
      );
      if (t.length > 0) {
        return t[0].title;
      }
      return "";
    },
    getMatchName(survey) {
      if (!survey.program_match) {
        return "";
      }
      const userId = Number(this.$route.params.participantId);
      const matchUser = survey.program_match.users.find(u => u.id !== userId);
      return matchUser ? matchUser.full_name : "";
    },
    getStatus(survey) {
      return survey?.status.name;
    },
    getNumberOfReminders(survey) {
      return survey?.reminder_count;
    },
    async fetchQuestionAnswers(survey) {
      try {
        this.isFetching = true;
        const programId = this.$route.params.id;
        const userId = this.$route.params.participantId;
        const formId = survey.form.id;
        const result = await surveyService.getUsersSurveyAnswers(
          programId,
          userId,
          formId,
          survey.match_id
        );
        const { data } = result;
        this.surveyQuestionAns = data.questions;
      } catch (e) {
        console.log(e);
        this.$toast(makeErrorToast("Error. Fetching users survey answers"));
        this.$log.error(e);
      } finally {
        this.isFetching = false;
      }
    },
    getQuestionTextTranslation(translations, localeId) {
      const tabTranslation = translations.filter(
        t => t.locale_id === localeId
      );
      if (tabTranslation.length > 0) {
        return tabTranslation[0].question_text;
      }
      return `${localesDisplay[localeId]} translation not found!`;
    },
    getQuestionChoicesAnswerTextTranslation(question, localeId) {
      if (question.choices.length > 0) {
        const choice = question.choices.flatMap(c =>
          c.translations.filter(t => t.locale_id === localeId)
        );
        if (choice.length > 0 && question.answers.length > 0) {
          const ansIds = question.answers.map(c => c.choice_id);
          if (ansIds.length > 0) {
            const matchedAns = choice.filter(c =>
              ansIds.includes(c.question_choice_id)
            );
            if (question?.answers[0]?.answer_text) {
              return `${matchedAns.map(a => a.choice_text).join(", ")} - ${
                question.answers[0].answer_text
              }`;
            }
            return matchedAns.map(a => a.choice_text).join(", ");
          }
        }
      }
      return;
    },
    getStatementsChoicesTranslation(question, localeId) {
      if (question.statements.length > 0) {
        const state = question.statements.filter(s => s.answers.length > 0);
        // find the translated choice
        if (state.length > 0) {
          const matchedChoice = question.choices
            .filter(c => c.id === state[0].answers[0].choice_id)
            .shift();
          const choiceTrans = matchedChoice.translations
            .filter(t => t.locale_id === localeId)
            .shift();
          const stateTrans = state[0].translations
            .filter(t => t.locale_id === localeId)
            .shift();
          if (choiceTrans && stateTrans) {
            return `${stateTrans.question_text} - ${choiceTrans.choice_text}`;
          }
        }
        return;
      }
      return;
    },
  },
  setup() {
    return {
      questionTypes,
      STATUS_COLOR,
      loaderTypes,
    };
  },
};
</script>
<style lang="scss" scoped>
.list-style-none {
  list-style: none;
}
ul {
  margin: 0;
  padding: 0;
}
.heading-wrapper {
  border-bottom: 1px solid #ccc;
}
::v-deep .tab-content {
  border: none !important;
}
.survey-wrapper {
  box-shadow: 0 3px 3px -2px var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0.2)),
    0 3px 4px 0 var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.14)),
    0 1px 8px 0 var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.12));
}
.question-wrapper {
  background: #f8f8f8;
  border-radius: 5px;
  padding: 20px;
}
</style>
