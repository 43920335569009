<template>
  <vue-good-table
    mode="remote"
    :is-loading="isLoading"
    :columns="columns"
    :rows="rows"
    :rtl="isRtl"
    :search-options="{
      enabled: false,
    }"
    :pagination-options="{
      enabled: true,
      perPage: perPage,
    }"
    style-class="vgt-table striped"
    @on-page-change="onPageChange"
    @on-sort-change="onSortChange"
    @on-per-page-change="onPerPageChange"
  >
    <template
      slot="table-row"
      slot-scope="props"
    >
      <!-- Column: Avatar -->
      <span v-if="props.column.field === 'profile_image'">
        <b-media>
          <template #aside>
            <b-avatar
              variant="primary"
              :text="userInitials(props.row)"
              :src="props.row.profile_image"
              size="5rem"
            />
          </template>
        </b-media>
      </span>

      <!-- Column: Full name -->
      <span v-else-if="props.column.field === 'full_name'">
        <b-link :to="{ name: 'champion-program-participant', params: { participantId: props.row.id } }">
          {{ props.row.full_name }}
        </b-link>
      </span>

      <!-- Column: Common -->
      <span v-else>
        {{ props.formattedRow[props.column.field] }}
      </span>
    </template>
    
    <!-- pagination -->
    <template
      slot="pagination-bottom"
      slot-scope="props"
    >
      <table-pagination
        :per-page="perPage"
        :total="total"
        @perPageChanged="
          (value) => props.perPageChanged({ currentPerPage: value })
        "
        @pageChanged="
          (value) => props.pageChanged({ currentPage: value })
        "
      />
    </template>
  </vue-good-table>
</template>

<script>
import { BMedia, BAvatar, BLink } from "bootstrap-vue";
import TablePagination from "@/views/components/pagination/TablePagination.vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store";
import { matchesService } from '@/services';
import { makeErrorToast } from "@/libs/utils";

export default {
  name: 'ParticipantsPreferredPartners',
  components: {
    BMedia,
    BAvatar,
    BLink,
    VueGoodTable,
    TablePagination
  },
  props: {
    user: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      isLoading: false,
      modalShow: false,
      perPage: 10,
      page: 1,
      columns: [
        {
          label: "Avatar",
          field: 'profile_image',
          sortable: false,
          width: '12em'
        },
        {
          label: "Full name",
          sortable: false,
          field: "full_name",
        }
      ],
      rows: [],
      columnFilters: [],
      sort: [],
      total: 0
    };
  },
  computed: {
    isRtl() {
      return store.state.appConfig.isRTL;
    }
  },
  created() {
    this.loadItems();
  },
  methods: {
    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },

    onPerPageChange(params) {
      this.perPage = params.currentPerPage;
      this.loadItems();
    },

    onSortChange(params) {
      let field = params[0].field;
      let type = params[0].type;
      if (type !== 'none') {
        this.sort = [{ field: field, type: type }];
      } else {
        this.sort = [];
      }
      this.loadItems();
    },

    async loadItems() {
      try {
        this.isLoading = true;
        const programId = this.$route.params.id;
        const userId = this.user.id;
        const response = await matchesService.getMatchPreferences(programId, userId, {
          page: this.page,
          perPage: this.perPage,
          columnFilters: this.columnFilters,
          sort: this.sort,
        });
        const { match_preferences }  = response.data;
        this.rows = match_preferences.map(match => ({
          ...match.preferenced_user,
          profile_image: match.preferenced_user.profile_image?.url || "",
        }));
      } catch (e) {
        console.log(e);
        this.$toast(makeErrorToast(e));
      } finally {
        this.isLoading = false;
      }
    },

    userInitials(name) {
      const firstInt = Array.from(name.first_name)[0];
      const lastInt = Array.from(name.last_name)[0];
      return `${firstInt}${lastInt}`;
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/vue/libs/vue-good-table.scss';
.nav .dropdown-toggle:not(.active)::after {
  background-image: none !important;
}
.vgt-left-align {
  vertical-align: middle !important;
}
</style>
