<template>
  <b-card>
    <b-col class="d-flex justify-content-end">
      <action-button
        variant="primary"
        size="sm"
        @click="open = true"
      >
        <feather-icon
          icon="PlusIcon"
          size="12"
          class="mr-50"
        />
        <span class="align-middle">Add Note</span>
      </action-button>
    </b-col>
    <!-- Note list -->
    <div v-if="isLoading">
      <aom-skeleton-loader />
    </div>
    <b-list-group
      v-else
      class="mt-1"
    >
      <template v-if="rows.length > 0">
        <b-list-group-item
          v-for="(row, index) in rows"
          :key="index"
        >
          <b-row align-h="between">
            <b-col style="font-weight: 600">
              {{
                formatDate(row.created_at)
              }}
            </b-col>
            <b-col
              class="d-flex justify-content-end align-items-center text-right"
            >
              <p class="text-primary m-0">
                {{ row.user.full_name }}
              </p>
              <b-button
                variant="outline-none"
                class="nav p-0"
              >
                <b-nav-item-dropdown
                  class="btn-icon"
                  right
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="20"
                    />
                  </template>
                  <action-dropdown-item @click="onEditNote(row)">
                    <feather-icon
                      icon="EditIcon"
                      size="20"
                      style="margin-right: 5px"
                    />
                    <span>Edit</span>
                  </action-dropdown-item>
                  <action-dropdown-item @click="onDeleteNote(row)">
                    <feather-icon
                      icon="TrashIcon"
                      size="20"
                      style="margin-right: 5px"
                    />
                    <span>Remove</span>
                  </action-dropdown-item>
                </b-nav-item-dropdown>
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12">
              {{ row.note_text }}
            </b-col>
          </b-row>
        </b-list-group-item>
      </template>
      <p
        v-else
        class="text-center"
      >
        Not found notes.
      </p>
    </b-list-group>

    <!-- Sidebars -->
    <champion-note-form
      :open="open"
      :note-id="noteId"
      @toggleSideBar="toggleAddSideBar"
      @noteAdded="onRefreshItems"
      @close="clear"
    />
  </b-card>
</template>

<script>
import ActionButton from "@/views/components/common/ActionButton.vue";
import ActionDropdownItem from "@/views/components/common/ActionDropdownItem.vue";
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BListGroup,
  BListGroupItem,
  BNavItemDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import { makeSuccessToast, makeErrorToast, localeDateStringFromIsoDateTime } from "@/libs/utils";
import { programsService } from "@/services";
import ChampionNoteForm from "./ChampionNoteForm.vue";
import { mapGetters } from "vuex";
import AomSkeletonLoader from "@aom-core/AomSkeletonLoader.vue";

export default {
  components: {
    ActionDropdownItem,
    BCard,
    BRow,
    BCol,
    BButton,
    BListGroup,
    BListGroupItem,
    BNavItemDropdown,
    BDropdownItem,
    ChampionNoteForm,
    AomSkeletonLoader,
    ActionButton
  },
  props: {
    user: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      open: false,
      rows: [],
      total: 0,
      noteId: 0,
    };
  },
  computed: {
    ...mapGetters("programs", ["defaultProgramId"]),
    currentParticipantId() {
      return this.$route.params.participantId;
    },
  },
  created() {
    this.loadItems();
  },
  methods: {
    async loadItems() {
      try {
        this.isLoading = true;
        const response = await programsService.getNoteListByUser(
          this.defaultProgramId,
          this.user.id
        );
        const { items, total } = response.data;
        this.total = total;


        this.rows = items.sort((a, b) => {
        const aStartsWithSuspended = a.note_text.toLowerCase().startsWith("participant suspended");
        const bStartsWithSuspended = b.note_text.toLowerCase().startsWith("participant suspended");

        // If one starts with "Participant suspended" and the other doesn't, prioritize the one that does
        if (aStartsWithSuspended && !bStartsWithSuspended) {
            return -1;
        }
        if (!aStartsWithSuspended && bStartsWithSuspended) {
            return 1;
        }

        // If both or neither start with "Participant suspended", maintain their original order
        return 0;
        });


      } catch (e) {
        this.$toast(makeErrorToast(e));
      } finally {
        this.isLoading = false;
      }
    },
    toggleAddSideBar(value) {
      if (!value) {
        this.clear();
      }
    },
    onRefreshItems() {
      this.loadItems();
    },
    clear() {
      this.open = false;
      this.noteId = 0;
    },
    formatDate(date) {
      return localeDateStringFromIsoDateTime(date, true);
    },
    onEditNote(note) {
      this.noteId = note.id;
      this.open = true;
    },
    async onDeleteNote(note) {
      const { id } = note;
      this.$bvModal
        .msgBoxConfirm("Are you sure you want to delete this Note ?", {
          title: "Delete Note",
          size: "sm",
          okVariant: "primary",
          okTitle: "Delete",
          cancelTitle: "Cancel",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if (value) {
            try {
              await programsService.deleteNote(
                this.defaultProgramId,
                this.currentParticipantId,
                id
              );
              this.$toast(makeSuccessToast("Note deleted successfully."));
              this.loadItems();
            } catch (e) {
              this.$log.error(e);
              this.$toast(
                makeErrorToast("Something went wrong. Note not deleted.")
              );
            }
          }
        });
    },
  },
};
</script>

<style lang="scss">
.nav .dropdown-toggle:not(.active)::after {
  background-image: none !important;
  display: none;
}
.nav-link {
  padding: 0;
}
</style>
