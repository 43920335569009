<template>
  <section>
    <b-row>
      <b-col
        sm="6"
        class="d-flex justify-content-start align-items-center"
      >
        <h5 class="ml-50">
          Member of Groups
        </h5>
      </b-col>
      <b-col
        sm="6"
        class="d-flex justify-content-end align-items-center mb-2"
      >
        <action-button
          variant="primary"
          @click="showModal"
        >
          Change Group Membership
        </action-button>
      </b-col>
    </b-row>
    
    <b-row
      v-for="(group, index) in groupsList"
      :key="index"
    >
      <b-col
        v-for="g in group"
        :key="g.id"
        sm="6"
      >
        <b-list-group>
          <b-list-group-item
            class="d-flex justify-content-between align-items-center group-wrapper"
            @click="viewGroup(g.id)"
          >
            <b-badge
              variant="primary"
              class="ml-25"
            >
              {{ groupTypeDisplay[g.type_id] }}
            </b-badge>
            {{ g.name }}
            <feather-icon
              icon="EyeIcon"
              size="18"
              class="mr-50"
            />
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
    <form-group-modal
      :show="shallShowFormGroupModal"
      :users="[user]"
      :users-groups="usersGroups"
      @close="closeFormGroup"
    />
  </section>
</template>
<script>
        
import ActionButton from "@/views/components/common/ActionButton.vue";
import {  
  BRow,
  BCol,
  BButton,
  BListGroupItem, 
  BListGroup, 
  BBadge
} from "bootstrap-vue";
import { makeErrorToast } from "@/libs/utils";
import { groupsService } from "@/services";
import _chunk from "lodash/chunk";
import FormGroupModal from "./actions/FormGroupModal.vue";
import { groupTypeDisplay } from '@models';

export default {
  name: 'ParticipantGroups',
  components: {
    ActionButton,
    BRow,
    BCol,
    BButton,
    BListGroupItem, 
    BListGroup,
    FormGroupModal,
    BBadge
  },
  props:{
    user: {
      type: Object,
      default: () => {},
      required: true
    },
    show: {
      type: Boolean,
    }
  },
  data() {
    return {
      shallShowFormGroupModal: false,
      isLoading: false,
      groupsList: [],
      usersGroups: [],
      groupTypeDisplay
    };
  },
  created() {
    this.fetchGroupList();
  }, 
  methods: {
    closeFormGroup() {
      this.shallShowFormGroupModal = false;
      this.fetchGroupList();
    },
    async fetchGroupList() {
      try {
        this.isLoading = true;
        const programId = this.$route.params.id;
        const userId = this.user.id;
        const response = await groupsService.getUserGroups(programId, userId);
        const {data} = response;
        if(data.length >0) {
          this.groupsList = _chunk(data, 10);
          this.usersGroups = data;
        }
      } catch (e) {
        console.log(e);
        this.$toast(makeErrorToast('Users Groups Not loaded!'));
      } finally {
        this.isLoading = false;
      }
    },
    
    showModal() {
      this.shallShowFormGroupModal = true;
    },
    viewGroup(groupId) {
      this.$router.push({name: 'champion-participants-filter-groups', params: {id: this.$route.params.id, groupId: groupId}});
    }
  }
};
</script>
<style lang="scss" scoped>
.group-wrapper {
    cursor: pointer;
}
</style>