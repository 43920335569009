<template>
  <section>
    <b-row>
      <b-col sm="12">
        <aom-skeleton-loader v-if="isLoading" />
      </b-col>
    </b-row>  
    <b-row v-if="!isLoading" />
    <b-col
      sm="12"
    >
      <b-row class="mt-2">
        <b-col sm="12">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="text-left text-muted mb-0">
              Matching Questions
            </h4>
            <p
              v-if="isCompleted && completedDate"
              class="text-right mb-0"
            >
              Completion date: {{ formatDate(completedDate) }}
            </p>
          </div>
          <hr>
        </b-col>
      </b-row>
      <ul class="list-style-none mb-2">
        <li
          v-if="matchingQuestionsList.length === 0"
          class="mb-2"
        >
          No Matching Questions on Application
        </li>
        <li
          v-for="question in matchingQuestionsList"
          :key="question.id"
        >
          <div
            class="list-style-none"
          >
            <p :class="textDirection">
              <span v-if="question.question_matched_pair && question.question_matched_pair.weight && textDirection === 'text-right'">
                <matched-question-weight :question="question" />
              </span>
              <b>{{ getQuestionTextTranslation(question.translations) }}</b>
              <span v-if="question.question_matched_pair && question.question_matched_pair.weight && textDirection === 'text-left'">
                <matched-question-weight :question="question" />
              </span>
            </p>
            <p
              :class="textDirection"
              class=""
            >
              <span v-if="!getQuestionChoicesAnswerTextTranslation(question)">&nbsp;</span>
              <ul
                v-for="(answer, index) in getQuestionChoicesAnswerTextTranslation(question)"
                :key="index"
                class="list-style-none"
              >
                <li class="text-primary">
                  {{ answer.choice_text }}
                </li>
              </ul>
            </p>
          </div>
        </li>
      </ul>
    </b-col>
    <b-col sm="12">
      <b-row>
        <b-col sm="12">
          <h4 class="text-left mt-2 text-muted">
            Other Questions
          </h4>
          <hr>
        </b-col>
      </b-row>
      
      <ul class="list-style-none">
        <li
          v-for="(question, index) in otherQuestionsList"
          :key="question.id"
        >
          <div
            class="list-style-none mb-2"
          >
            <p
              :class="textDirection"
              class="mb-50"
            >
              <b>Q{{ index + 1 }}: {{ getQuestionTextTranslation(question.translations) }}</b>
            </p>
            <p
              :class="textDirection"
              class="mb-50 d-inline font-italic"
            >
              <span 
                v-if="(question.type_id === questionTypes.TEXT && question.answers.length > 0)"
              >
                A: {{ question.answers[0].answer_text }}
              </span>
              <span 
                v-if="(question.type_id === questionTypes.SCALE)"
              >
                <p
                  v-for="(s, i) in getStatementsChoicesTranslation(question)"
                  :key="i"
                >
                  A: {{ s }}
                </p>
              </span>
              <span v-if="(question.type_id === questionTypes.CHOICE)">
                <ul
                  v-for="(answer, answerIndex) in getQuestionChoicesAnswerTextTranslation(question)"
                  :key="answerIndex"
                  class="list-style-none"
                >
                  <li>
                    A: {{ answer.choice_text }}
                    <span v-if="answer.answer_text"><br>{{ answer.answer_text }}</span>
                  </li>
                </ul>
              </span>
              <span v-else>&nbsp;</span>
            </p>
          </div>
        </li>
      </ul>
    </b-col>
  </section> 
</template>
        
<script>
import {
  BRow,
  BCol,
  VBToggle,
} from "bootstrap-vue";
import { mapGetters } from 'vuex';
import { questionTypes } from '@models/questionTypes';
import { userRoles, localesDisplay, formStatus } from "@models";
import applicationService from "@/services/applicationService";
import AomSkeletonLoader from "@aom-core/AomSkeletonLoader.vue";
import MatchedQuestionWeight from "@/views/apps/champion/matches/MatchedQuestionWeight.vue";
import { localeDateStringFromIsoDateTimeWithoutTimezone } from "@/libs/utils";

export default {
  name: "ParticipantApplicationAnswers",
  components: {
    BRow,
    BCol,
    AomSkeletonLoader,
    MatchedQuestionWeight
  },
  directives: {
    "b-toggle": VBToggle,
  },
  props: {
    user: {
      type: Object,
      default:() => {},
      required: true
    },
    localeId: {
      type: Number,
      required: true
    }, 
    roleId: {
      type: Number,
      required: true,
      validator (value) {
        return Object.values(userRoles).indexOf(value) !== -1;
      },
    }
  },
  data() {
    return {
      visible: true,
      matchingQuestionsList: [],
      otherQuestionsList: [],
      isLoading: false,
      isCompleted: false,
      completedDate: null
    };
  },
    
  computed: {
    ...mapGetters('programs',['defaultProgramId', 'defaultProgram']),
    textDirection() {
      return 'text-left';
    },
    userApplication() {
      return this.defaultProgram.application_set.applications.filter(a => a.roles.find(r => r.id === this.roleId)).shift();
    },
    userFormId() {
      return this.userApplication?.id;
    }
  },  
  created(){
    this.fetchUserApplication();
  },
  methods: {
    formatDate(date) {
      return localeDateStringFromIsoDateTimeWithoutTimezone(date);
    },
    async fetchUserApplication() {
      try {
        this.isLoading = true;
        const params = {
          programId: this.defaultProgramId,
          userId: this.user.id,
          formId: this.userFormId
        };
        const result = await applicationService.getOwnApplicationAnswers(params);
        const {data} = result;
        if (data.user_forms.length) {
          this.isCompleted = Number(data.user_forms[0].status_id) === formStatus.COMPLETED;
          this.completedDate = data.user_forms[0].completed_at;
        }
        this.matchingQuestionsList = data.questions.filter(q => q.type_id === questionTypes.MATCHING);
        this.otherQuestionsList = data.questions.filter(q => q.type_id !== questionTypes.MATCHING);
      } catch (e) {
        console.log(e);
        this.$log.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    getQuestionTextTranslation(translations) {
      const tabTranslation = translations.filter(t => t.locale_id === this.localeId);
      if(tabTranslation.length > 0) {
        return tabTranslation[0].question_text;
      }
      return `${localesDisplay[this.localeId]} translation not found!`;
    },
    getQuestionChoicesAnswerTextTranslation(question) {
      if(question.choices.length > 0 ) {
        return question.answers.map(answer => {
          const translation = question.choices.find(choice => choice.id === answer.choice_id)?.translations.find(translation => translation.locale_id === this.localeId);
          return {...answer, 'choice_text': translation?.choice_text};
        });
      }
      return;
    },
    getStatementsChoicesTranslation(question) {
      if(question.statements.length > 0 ) {
        const state = question.statements.filter(s => s.answers.length > 0);
        // find the translated choice
        if(state.length > 0) {
          let answ = [];
          state.forEach(s => {
            const matchedChoice = question.choices.filter(c => c.id === s.answers[0].choice_id).shift();
            const choiceTrans = matchedChoice?.translations.filter(t => t.locale_id === this.localeId).shift();
            const stateTrans = s.translations.filter(t => t.locale_id === this.localeId).shift();
            if(choiceTrans && stateTrans) {
              answ.push(`${stateTrans.question_text} - ${choiceTrans.choice_text}`);
            }
          });
          return answ;
        }
        return;
      }
      return;
    }
  },
  setup() {
    return {
      questionTypes
    };
  },
};
</script>
<style lang="scss" scoped>
    .list-style-none {
        list-style: none;
    }
    ul {
        margin: 0;
        padding:0;
    }
</style>