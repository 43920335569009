<template>
  <div>
    <b-card style="margin-bottom: 1.5rem;">
      <b-row>
        <b-col
          sm="9"
          md="8"
        >
          <b-media>
            <template #aside>
              <b-avatar
                variant="primary"
                :text="userInitials"
                :src="userProfileImage"
                size="7rem"
              />
              <p class="text-center mt-1">
                {{ userRole }}
              </p>
            </template>

            <h6>
              <b-badge :variant="STATUS_COLOR[userOverviewStatus]">
                {{ userOverviewStatus }}
              </b-badge>
            </h6>
        
            <h4 class="mt-1 font-weight-500">
              {{ user.full_name }}
            </h4>
            <b-row class="ml-0 mt-1">
              <p class="mr-1">
                <feather-icon
                  icon="MailIcon"
                  size="16"
                />
                {{ user.email }}
              </p>
              <p class="mr-1">
                <feather-icon
                  icon="PhoneIcon"
                  size="16"
                />
                Phone: {{ user.phone }}
              </p>
              <p class="mr-1">
                <feather-icon
                  icon="CalendarIcon"
                  size="16"
                />
                Joined: {{ localeDateStringFromIsoDateTime(user.created_at) }}
              </p>
              <p class="mr-1">
                <feather-icon
                  icon="CalendarIcon"
                  size="16"
                />
                Last active: {{ user.activities.length? localeDateStringFromIsoDateTime(user.activities[0].updated_at): null }}
              </p>
            </b-row>
          </b-media>      
        </b-col>
        <b-col
          sm="3"
          md="4"
          class="d-flex flex-column justify-content-center"
        >
          <div class="d-flex flex-column align-items-center align-items-md-end">
            <span
              id="disabled-wrapper-mentee"
              tabindex="0"
            >
              <action-button
                v-if="userIsMentor"
                :disabled="!canSelectMentee"
                variant="primary"
                class="mb-50"
                size="sm"
                @click="loadMatchesList(userRoles.MENTEE, userRoles.MENTOR)"
              >
                <feather-icon
                  size="16"
                  icon="UserIcon"
                  class="mr-50"
                />
                Find a {{ roleAlias(userRoles.MENTEE, defaultProgram) }}
              </action-button>
            </span>
            <b-tooltip
              v-if="!canSelectMentee"
              target="disabled-wrapper-mentee"
            >
              {{ mentorCannotMatchMsg }}
              {{ programMatchLimitMessage }}
              {{ disabledMsg }}
            </b-tooltip>
            <span
              id="disabled-wrapper-mentor"
              class="d-inline-block"
              tabindex="0"
            >
              <b-button
                v-if="userIsMentee"
                :disabled="!canSelectMentor"
                variant="primary"
                class="mb-50"
                size="sm"
                @click="loadMatchesList(userRoles.MENTOR, userRoles.MENTEE)"
              >
                <feather-icon
                  size="16"
                  icon="UserIcon"
                  class="mr-50"
                />
                Find a {{ roleAlias(userRoles.MENTOR, defaultProgram) }}
              </b-button>
            </span>
            <b-tooltip
              v-if="!canSelectMentor"
              target="disabled-wrapper-mentor"
            >
              {{ menteeCannotMatchMsg }}
              {{ programMatchLimitMessage }}
              {{ disabledMsg }}
            </b-tooltip>
            <action-dropdown
              text="Action"
              class="ml-lg-1 mb-50"
              variant="primary"
              size="sm"
              right
            >
              <b-dropdown-item
                link-class="d-flex align-items-center"
                @click="launchTrainingHistory()"
              >
                <feather-icon
                  size="16"
                  icon="FilePlusIcon"
                  class="mr-50"
                />
                <span>Training Launch History</span>
              </b-dropdown-item>
              <b-dropdown-item
                link-class="d-flex align-items-center"
                @click="openEditUser"
              >
                <feather-icon
                  size="16"
                  icon="EyeIcon"
                  class="mr-50"
                />
                <span>Edit Profile</span>
              </b-dropdown-item>
              <b-dropdown-item
                link-class="d-flex align-items-center"
                @click="launchActivityReport"
              >
                <feather-icon
                  size="16"
                  icon="SettingsIcon"
                  class="mr-50"
                />
                <span>Activity Report</span>
              </b-dropdown-item>
              <b-dropdown-item
                :disabled="!isUserActive"
                link-class="d-flex align-items-center"
                @click="sendMessage"
              >
                <feather-icon
                  size="16"
                  icon="MessageSquareIcon"
                  class="mr-50"
                />
                <span>Send Message</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="isUserActive"
                link-class="d-flex align-items-center"
                @click.prevent="loginAs(user.id)"
              >
                <feather-icon
                  size="16"
                  icon="UserIcon"
                  class="mr-50"
                />
                <span>Login as</span>
              </b-dropdown-item>
              <resend-access
                :user="user"
                :user-id="user.id"
              />
              <b-dropdown-item
                v-if="userIsMentor"
                link-class="d-flex align-items-center"
                @click="editMentorApplication"
              >
                <feather-icon
                  size="16"
                  icon="ListIcon"
                  class="mr-50"
                />
                <span>Edit {{ roleAlias(userRoles.MENTOR, defaultProgram) }} Application</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="userIsMentee"
                link-class="d-flex align-items-center"
                @click="editMenteeApplication"
              >
                <feather-icon
                  size="16"
                  icon="ListIcon"
                  class="mr-50"
                />
                <span>Edit {{ roleAlias(userRoles.MENTEE, defaultProgram) }} Application</span>
              </b-dropdown-item>
              <!-- Suspend User -->
              <suspend-user
                :user="user"
                :user-id="user.id"
                @updated="updateUser"
              />
              <remove-user
                :user="user"
                :user-id="user.id"
                @updated="removeUser"
              />
            </action-dropdown>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <b-card class="mb-0">
      <b-row
        align-v="center"
        align-h="between"
        class="user-info"
      >
        <b-col
          sm="12"
          md="auto"
        >
          <b-card-sub-title class="d-flex align-items-center mb-2">
            <feather-icon
              icon="CheckIcon"
              size="16"
              class="mr-50"
            />
            {{ $t('Email Verified') }}:&nbsp;
            <b-card-text
              v-if="user.email_verified_at"
              class="d-inline text-dark"
            >
              Yes
            </b-card-text>
            <b-card-text
              v-else
              class="d-inline text-danger"
            >
              No
            </b-card-text>
          </b-card-sub-title>
        </b-col>
        <b-col
          v-if="userIsMentor"
          sm="12"
          md="auto"
        >
          <b-card-sub-title class="d-flex align-items-center mb-2">
            <feather-icon
              icon="UserIcon"
              size="16"
              class="mr-50"
            />
            {{ roleAlias(userRoles.MENTOR, defaultProgram) }} Training:&nbsp;
            <b-card-text class="d-inline text-dark">
              {{ user.mentorTraining }}
            </b-card-text>
          </b-card-sub-title>
        </b-col>
        <b-col
          v-if="userIsMentee"
          sm="12"
          md="auto"
        >
          <b-card-sub-title class="d-flex align-items-center mb-2">
            <feather-icon
              icon="UserIcon"
              size="16"
              class="mr-50"
            />
            {{ roleAlias(userRoles.MENTEE, defaultProgram) }} Training:&nbsp;
            <b-card-text class="d-inline text-dark">
              {{ user.menteeTraining }}
            </b-card-text>
          </b-card-sub-title>
        </b-col>
        <b-col
          sm="12"
          md="auto"
        >
          <b-card-sub-title class="d-flex align-items-center mb-2">
            <feather-icon
              icon="MailIcon"
              size="16"
              class="mr-50"
            />
            {{ $t('Email Notifications') }}:&nbsp;
            <b-card-text class="d-inline text-dark">
              <span>
                {{ hasEmailNotifications ? 'On' : 'Off' }}
              </span>
            </b-card-text>
          </b-card-sub-title>
        </b-col>
        <b-col
          v-if="userIsMentor"
          sm="12"
          md="auto"
        >
          <b-card-sub-title class="d-flex align-items-center mb-2">
            <feather-icon
              icon="UsersIcon"
              size="16"
              class="mr-50"
            />
            {{ $t('Mentee allowed') }}:&nbsp;
            <b-card-text class="d-inline text-dark">
              <span>
                {{ menteeAllowed }}
              </span>
            </b-card-text>
          </b-card-sub-title>
        </b-col>
      </b-row>
    </b-card>

    <create-match
      :open="openCreateMatch"
      title="Create a match"
      :user="user"
      :role="matchRole"
      :reciprocal-role="reciprocalRole"
      @close="closeSideBar"
    />
    <b-sidebar
      id="edit-participant"
      v-model="openEditSideBar"
      title="Edit Participant"
      bg-variant="white"
      aria-controls="edit-participant-sidebar"
      :aria-expanded="openEditSideBar"
      right
      shadow
      backdrop
      width="50rem"
    >
      <participant-edit
        ref="editParticipant"
        :open="openEditSideBar"
        :participant-id="user.id"
        @userUpdated="clearEditForm"
        @close="clearEditForm"
      />
    </b-sidebar>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BMedia,
  BAvatar, BCardText, BCardSubTitle, BBadge, BButton, BTooltip,
  BDropdownItem, BDropdown, BSidebar
} from 'bootstrap-vue';
import { localeDateStringFromIsoDateTime, makeErrorToast } from "@/libs/utils";
import { STATUS_COLOR } from "@/libs/utils";
import { userRoles, userRolesDisplay, userStatus, userStatusDisplay, programTypes} from '@models';
import { mapGetters } from 'vuex';
import CreateMatch from '../champion/matches/CreateMatch.vue';
import ParticipantEdit from "./ParticipantEdit.vue";
import ResendAccess from './actions/ResendAccess';
import SuspendUser  from './actions/SuspendUser';
import RemoveUser from './actions/RemoveUser';
import { authService } from "@/services";
import { AOM_MAIN_DOMAIN, AOM_PORTAL_SUB_DOMAIN } from '@/constants/app';
import { roleAlias } from "@/@aom-core/utils/utils";
import ActionButton from "@/views/components/common/ActionButton.vue";
import ActionDropdown from "@/views/components/common/ActionDropdown.vue";

export default {
  components: {
    ActionButton,
    ActionDropdown,
    BRow,
    BCol,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BCardSubTitle,
    BBadge,
    BButton,
    BTooltip,
    CreateMatch,
    BDropdownItem, 
    BDropdown, 
    ParticipantEdit,
    BSidebar,
    ResendAccess,
    SuspendUser,
    RemoveUser
  },
  props: {
    user: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      localeDateStringFromIsoDateTime,
      userRoles,
      matchedUsersList: [],
      openCreateMatch: false,
      isLoadingMatches: false,
      matchRole: 0,
      reciprocalRole: 0,
      mentorCannotMatchMsg: '',
      menteeCannotMatchMsg: '',
      disabledMsg: '',
      openEditSideBar: false
    };
  },
  computed: {
    ...mapGetters('programs',['defaultProgram', 'defaultProgramId']),
    userInitials() {
      const firstInt = Array.from(this.user.first_name)[0];
      const lastInt = Array.from(this.user.last_name)[0];
      return `${firstInt}${lastInt}`;
    },
    userMenteeStatus() {
      const mentee  = this.user.user_roles.filter(role => role.role_id === userRoles.MENTEE).shift();
      if(mentee) {
        for (const [, value] of Object.entries(userStatus)) {
          if (Number(value) === Number(mentee.status_id)) {
            return userStatusDisplay[value];
          }
        }
      }
      return "";
    },
    userMentorStatus() {
      const mentor  = this.user.user_roles.filter(role => role.role_id === userRoles.MENTOR).shift();
      if(mentor) {
        for (const [, value] of Object.entries(userStatus)) {
          if (Number(value) === Number(mentor.status_id)) {
            return userStatusDisplay[value];
          }
        }
      }
      return "";
    },
    userOverviewStatus() {
      const filteredRoles = this.user.user_roles;
      const programRoles = filteredRoles.filter(r => Number(r.program_id)=== Number(this.$route.params.id));
      return userStatusDisplay[programRoles.length ? programRoles[programRoles.length - 1].status_id : userStatus.INACTIVE];
    },
    userIsMentee () {
      return  this.user.user_roles.findIndex(role => role.role_id === userRoles.MENTEE && Number(role.program_id)=== Number(this.$route.params.id)) !== -1;
    },
    userIsMentor () {
      return  this.user.user_roles.findIndex(role => role.role_id === userRoles.MENTOR && Number(role.program_id)=== Number(this.$route.params.id)) !== -1;
    },
    menteeAllowed() {
      return this.userIsMentor ? this.user.user_roles.find(role => role.role_id === userRoles.MENTOR && Number(role.program_id)=== Number(this.$route.params.id)).match_limit : 0;
    },
    userProfileImage () {
      return this.user?.profile_image?.url || '';
    },
    canSelectMentor () {
      const mentee = this.user.user_roles.filter(role => role.role_id === userRoles.MENTEE).shift();
      if(mentee) {
        if((Number(mentee.locked_and_active_matches_count) > 0)) {
          this.setMenteeMatchLimitMsg(mentee);
        }
        if(Number(mentee.status_id) !== Number(userStatus.ACTIVE)) {
          this.setUserIsNotActiveMsg();
        }
        return this.userIsMentee &&
        Number(mentee.status_id) === Number(userStatus.ACTIVE) && 
        Number(mentee.locked_and_active_matches_count) === 0 &&  
        !this.isProgramMatchLimitExceeded ;
      }
      return false;
    },
    canSelectMentee () {
      const mentor = this.user.user_roles.filter(role => role.role_id === userRoles.MENTOR).shift();
      if(mentor) {
        if(!(Number(mentor.match_limit) - Number(mentor.locked_and_active_matches_count) > 0)) {
          this.setMentorMatchLimitMsg(mentor);
        }
        if(Number(mentor.status_id) !== Number(userStatus.ACTIVE)) {
          this.setUserIsNotActiveMsg();
        }
        return this.userIsMentor && 
        Number(mentor.status_id) === Number(userStatus.ACTIVE) && 
        (Number(mentor.match_limit) - Number(mentor.locked_and_active_matches_count) > 0) &&
        (this.defaultProgram.type_id === programTypes.MENTORING_ON_DEMAND || !this.isProgramMatchLimitExceeded) ;
      }
      return false;
    },
    isProgramMatchLimitExceeded() {
      return this.defaultProgram?.matches_count >= this.defaultProgram?.match_limit;
    },
    programMatchLimitMessage () {
      return this.isProgramMatchLimitExceeded ? `Match limit has been reached. Do you want to purchase more matches? Please contact support.`: '';
    },
    isUserActive() {
      return Number(this.user.user_roles[0].status_id)  === Number(userStatus.ACTIVE);
    },
    hasEmailNotifications() {
      if(this.user.user_program_settings && this.user.user_program_settings.length > 0) {
        return Boolean(this.user.user_program_settings.filter(s => Number(s.program_id) ===  Number(this.$route.params.id) && Boolean(s.email_notifications) === true).length > 0);
      }
      return false;
    },
    numberOfMenteesAllowed() {
      const mentor = this.user.user_roles.find(ur => ur.role_id === userRoles.MENTOR && Number(ur.program_id) === Number(this.$route.params.id));
      return mentor ? mentor.match_limit : 0;
    },
    isUserInactive() {
      return this.user.user_roles.find(ur => ur.status_id === userStatus.INACTIVE) !== undefined;
    },
    userRole() {
      const roles = this.user.user_roles.filter(ur => ur.role_id === userRoles.MENTOR || ur.role_id === userRoles.MENTEE)
        .map(ur => {
          return this.defaultProgram.program_roles.find(r => r.role_id === ur.role_id)?.alternative_role_name || userRolesDisplay[ur.role_id];
        });
      return roles.join(", ");
    },
  },
  methods: {
    async loginAs(userId) {
      try {
        const result = await authService.makeloginAsUserToken(this.defaultProgramId, userId);
        const token = result.data.token;
        const protocol = window.location.protocol;
        const link = `${protocol}//${AOM_PORTAL_SUB_DOMAIN}.${AOM_MAIN_DOMAIN}/${this.defaultProgram.client.path}/${this.defaultProgram.path}/login?token=${token}`;
        window.open(link);
      } catch(e) {
        const { data } = e.response;
        this.$toast(makeErrorToast(data.message));
      }
      
    },
    clearEditForm() {
      this.$emit('update');
      this.$refs.editParticipant.clearForm();
      this.openEditSideBar = false;
    },
    openEditUser() {
      this.openEditSideBar = true;
    },
    sendMessage() {
      this.$router.push({name: 'champion-program-communications', query: {user_ids: this.user.id}});
    },
    async loadMatchesList(role, reciprocalRole) {
      const programId = this.defaultProgramId;
      const userId = this.user.id;
      if(!programId || !userId || !role) {
        throw new Error('Missing parameters.');
      }
      this.openCreateMatch = true;
      this.matchRole = role;
      this.reciprocalRole = reciprocalRole;
      
    },
    updateUser() {
      this.$emit('update');
    },
    removeUser() {
      this.$emit('remove');
    },
    closeSideBar() {
      this.openCreateMatch = false;
    },
    setMentorMatchLimitMsg (mentor) {
      this.mentorCannotMatchMsg = `Mentor match limit of ${mentor.match_limit} has been reached.`;
    },
    setMenteeMatchLimitMsg () {
      this.menteeCannotMatchMsg = `This user is already locked in a match. You must unlock that match before they can be matched with anyone else`;
    },
    setUserIsNotActiveMsg () {
      this.disabledMsg = `User is not Active.`;
    },
    editMentorApplication () {
      this.$router.push({name: "champion-program-mentor-application-edit", params: {applicationId: this.defaultProgram.application_set.id, id: this.$route.params.id, participantId: this.$route.params.participantId}});
    },
    editMenteeApplication () {
      this.$router.push({name: "champion-program-mentee-application-edit", params: {applicationId: this.defaultProgram.application_set.id, id: this.$route.params.id, participantId: this.$route.params.participantId}});
    },
    launchTrainingHistory() {
      this.$router.push({name: "champion-program-participant-training-logs", params: {id: this.$route.params.id, participantId: this.$route.params.participantId}});
    },
    launchActivityReport() {
      this.$router.push({name: "champion-program-participant-activity-report", params: {id: this.$route.params.id, participantId: this.$route.params.participantId, user: this.user}});
    },
    filterUserRoles(roles) {
      return roles.filter(r => r.role_id !== userRoles.PARTICIPANT_CANDIDATE);
    }
  },
  setup() {
    return {
      STATUS_COLOR,
      userStatus,
      userStatusDisplay,
      roleAlias,
      userRoles
    };
  }
};
</script>
<style lang="scss" scoped>
.max-image-height {
  max-height: 140px;
}
.font-weight-500 {
  font-weight: 500;
}
</style>
<style lang="scss">
.b-sidebar > .b-sidebar-header {
  display: flex;
  flex-direction: row-reverse;
}
$sidebar-header-text-color: #808080;
  .b-sidebar > .b-sidebar-header{
    flex-direction: row-reverse;
    background: #f3f3f3;
    color: $sidebar-header-text-color;

    #edit-participant___title__ {
      font-size: .8em;
      flex: 2;
    }
  }
.user-info {
  .card-subtitle {
    margin: 0 !important;
  }
}
.media-aside {
  flex-direction: column;
}
</style>