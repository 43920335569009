<template>
  <section>
    <b-sidebar
      id="add-note"
      v-model="openSideBar"
      :title="title"
      bg-variant="white"
      right
      shadow
      backdrop
      aria-controls="add-note"
      :aria-expanded="openSideBar"
      width="50%"
    >
      <validation-observer
        v-if="openSideBar"
        ref="observer"
        v-slot="{ invalid, handleSubmit }"
      >
        <b-form @submit.prevent="handleSubmit(onSubmit)">
          <aom-languages>
            <validation-provider
              v-slot="validationContext"
              ref="note"
              rules="required"
              name="note"
            >
              <b-form-group
                label="Note"
                label-for="note"
                class="mb-0"
              >
                <b-form-textarea
                  v-model="note_text"
                  placeholder="Enter new note here..."
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </aom-languages>
          <b-card>
            <b-row>
              <b-col
                sm="6"
                md="4"
                class="mb-1"
              >
                <b-button
                  block
                  type="reset"
                  variant="outline-dark"
                  @click="clearForm"
                >
                  Cancel
                </b-button>
              </b-col>
              <b-col
                sm="6"
                md="4"
                class="mb-1"
              >
                <b-button
                  block
                  type="submit"
                  variant="primary"
                  :disabled="isLoading || invalid"
                >
                  <b-spinner
                    v-if="isUpdating"
                    small
                  />
                  <span v-if="isUpdating"> Updating...</span>
                  <span v-else>Submit</span>
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-form>
      </validation-observer>
    </b-sidebar>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BSidebar,
  BButton,
  BSpinner,
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BFormTextarea,
} from "bootstrap-vue";
import AomLanguages from "@aom-core/AomLanguages";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { makeSuccessToast, makeErrorToast } from "@/libs/utils";
// eslint-disable-next-line
import { required } from "@validations";
import { getValidationState } from "@/libs/utils";
import programsService from "@/services/programsService";
import { mapGetters } from "vuex";

export default {
  name: "AddNoteSideBar",
  components: {
    BCard,
    BRow,
    BCol,
    BSidebar,
    AomLanguages,
    BButton,
    BSpinner,
    BForm,
    ValidationObserver,
    ValidationProvider,
    BFormGroup,
    BFormInvalidFeedback,
    BFormTextarea,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    noteId: {
      type: Number,
      default: 0,
    },
  },
  data(vm) {
    return {
      openSideBar: vm.open,
      note_text: "",
      isLoading: false,
      isUpdating: false,
    };
  },
  computed: {
    ...mapGetters("programs", ["defaultProgramId"]),
    currentParticipantId() {
      return this.$route.params.participantId;
    },
    title() {
      if (this.noteId) {
        return "Edit Note";
      }
      return "Add Note";
    },
  },
  watch: {
    open() {
      this.openSideBar = this.open;
    },
    openSideBar(n) {
      this.$emit("toggleSideBar", n);
    },
    noteId(n) {
      if (n) {
        this.loadItems();
      }
    },
  },
  methods: {
    async onSubmit() {
      try {
        this.isUpdating = true;
        if (this.noteId) {
          await programsService.updateNote(
            this.defaultProgramId,
            this.currentParticipantId,
            this.noteId,
            { note_text: this.note_text }
          );
        } else {
          await programsService.createNote(
            this.defaultProgramId,
            this.currentParticipantId,
            { note_text: this.note_text }
          );
        }
        this.$emit("noteAdded");
        this.$toast(
          makeSuccessToast(
            `Note ${this.noteId ? "updated" : "added"} successfully.`
          )
        );
      } catch (e) {
        this.$toast(
          makeErrorToast(
            `Something went wrong. Note not ${
              this.noteId ? "updated" : "added"
            }.`
          )
        );
      } finally {
        this.isUpdating = false;
        this.clearForm();
      }
    },
    closeSideBar() {
      this.openSideBar = false;
      this.$emit("close");
    },
    clearForm() {
      this.note_text = "";
      this.closeSideBar();
    },
    async loadItems() {
      try {
        this.isLoading = true;
        const reponse = await programsService.getNoteByUser(
          this.defaultProgramId,
          this.currentParticipantId,
          this.noteId
        );
        const { data } = reponse;
        this.note_text = data.note_text;
      } catch (e) {
        this.$toast(makeErrorToast("Something went wrong. Note not loaded."));
      } finally {
        this.isLoading = false;
      }
    },
  },
  setup() {
    return {
      getValidationState,
    };
  },
};
</script>

<style lang="scss" scoped>
  ::v-deep .tab-content {
    border: none !important;
  }
</style>